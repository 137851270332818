<template>
  <div class="page-container">
    <b-row>
      <!-- Media -->
      <b-col
        sm="3"
        md="3"
        lg="3"
        xl="3"
        class="mt-2"
      >
        <b-row>
          <!--=============== Biểu trưng=================-->
          <b-col class="avatar-unit">
            <CusttomAvatar
              id="Acount"
              :urlImg="accountInformation"
              idInput="BTInput"
              label=""
              width="200px"
              height="200px"
              class="image-logo-unit"
              @url="imgBT($event)"
            />
          </b-col>
        </b-row>
      </b-col>

      <!-- User Info: Input Fields -->
      <b-col>
        <validation-observer ref="rule">
          <b-form class="content-form">
            <b-row>
              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- name -->
                <b-form-group label-for="basicInput">
                  <label for="InputHelp">Mã đơn vị</label>
                  <b-form-input
                    id="basicInput"
                    v-model="accountInformation.code"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- sign-name -->
                <b-form-group label-for="basicInput">
                  <label for="InputHelp">Tên đơn vị <span
                    class="required"
                  >(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="userName"
                    rules="required|min:6"
                    :custom-messages="{required: `Tên đơn vị là bắt buộc`}"
                  >
                    <b-form-input
                      id="basicInput"
                      v-model="accountInformation.fullName"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- password -->
                <b-form-group>
                  <label for="InputHelp">Tỉnh/Thành phố <span class="required">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required"
                    :custom-messages="{required: `Tỉnh/Thành phố là bắt buộc`}"
                  >
                    <v-select
                      v-model="accountInformation.provinceId"
                      :reduce="data => data.id"
                      :label="'name'"
                      :placeholder="`Lựa chọn tỉnh/Thành phố `"
                      :options="provinces"
                      :class="{'is-invalid': !!errors.length}"
                      :state="errors.length > 0 ? false:null"
                      @input="openSelectDistrict"
                    >
                      <template v-slot:no-options="{ searching }">
                        <template v-if="searching">
                          Không có kết quả.
                        </template>
                        <em
                          v-else
                        >Không có dữ liệu</em>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- </div> -->
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- password -->
                <b-form-group>
                  <label for="InputHelp">Quận/Huyện <span class="required">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required"
                    :custom-messages="{required: `Quận/Huyện là bắt buộc`}"
                  >
                    <v-select
                      v-model="accountInformation.districtId"
                      :reduce="data => data.id"
                      :label="'name'"
                      :placeholder="`Lựa chọn quận/Huyện `"
                      :options="districts"
                      :class="{'is-invalid': !!errors.length}"
                      :state="errors.length > 0 ? false:null"
                      :disabled="choiceDistrict ? false : true"
                      @input="openSelectWard"
                    >
                      <template v-slot:no-options="{ searching }">
                        <template v-if="searching">
                          Không có kết quả.
                        </template>
                        <em
                          v-else
                        >Không có dữ liệu</em>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- </div> -->
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- password -->
                <b-form-group>
                  <label for="InputHelp">Phường/Xã <span class="required">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required"
                    :custom-messages="{required: `Phường/Xã là bắt buộc`}"
                  >
                    <v-select
                      v-model="accountInformation.wardId"
                      :reduce="data => data.id"
                      :label="'name'"
                      :placeholder="`Lựa chọn phường/Xã `"
                      :options="wards"
                      :class="{'is-invalid': !!errors.length}"
                      :state="errors.length > 0 ? false:null"
                      :disabled="choiceWard ? false : true"
                    >
                      <template v-slot:no-options="{ searching }">
                        <template v-if="searching">
                          Không có kết quả.
                        </template>
                        <em
                          v-else
                        >Không có dữ liệu</em>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- </div> -->
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- email -->
                <b-form-group label-for="basicInput">
                  <label for="InputHelp">Địa chỉ</label>
                  <b-form-input
                    id="basicInput"
                    v-model="accountInformation.address"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- email -->
                <b-form-group label-for="basicInput">
                  <label for="InputHelp">Số điện thoại</label>
                  <b-form-input
                    id="basicInput"
                    v-model="accountInformation.phoneNumber"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- email -->
                <b-form-group label-for="basicInput">
                  <label for="InputHelp">Fax</label>
                  <b-form-input
                    id="basicInput"
                    v-model="accountInformation.fax"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
                class="mb-1"
              >
                <!-- email -->
                <b-form-group label-for="basicInput">
                  <label for="InputHelp">Giám đốc/Chủ tịch</label>
                  <b-form-input
                    id="basicInput"
                    v-model="accountInformation.manager"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
    <div
      class="mt-2"
    >
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="hadnleCreate"
      >
        Lưu lại
      </b-button>
      <!-- <b-button
        variant="outline-primary"
        type="reset"
        @click="backUser"
      >
        Quay lại
      </b-button> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import CusttomAvatar from '@/views/settings/setting/pages/components/CusttomAvatar.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    vSelect,
    CusttomAvatar,
    BButton,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      email,
      userRole: JSON.parse(localStorage.getItem('userData')),
      accountInformation: {
        avatar: '',
        code: '',
        fullName: '',
        provinceId: 0,
        wardId: 0,
        districtId: 0,
        address: '',
        website: '',
        manager: '',
        userTypeId: '',
        userId: '',
        management: '',
      },
      dataUserId: {},
      choiceDistrict: false,
      choiceWard: false,
      arrContry: [],
      provinces: [],
      districts: [],
      wards: [],
    }
  },
  watch: {
    async dataUserId(val) {
      if (val) {
        this.accountInformation = this.dataUserId
        this.accountInformation = {
          avatar: this.dataUserId.avatar,
          code: this.dataUserId.code,
          fullName: this.dataUserId.fullName,
          provinceId: this.dataUserId.provinceId,
          wardId: this.dataUserId.wardId,
          districtId: this.dataUserId.districtId,
          address: this.dataUserId.address,
          phoneNumber: this.dataUserId.phoneNumber,
          fax: this.dataUserId.fax,
          manager: this.dataUserId.manager,
          userTypeId: this.dataUserId.userTypeId,
          userId: this.dataUserId.userId,
          management: this.dataUserId.management,
        }
        if (this.dataUserId.provinceId) {
          this.districts = await this.fecthDistricts(this.dataUserId.provinceId)
          this.choiceDistrict = true
        }
        if (this.dataUserId.districtId) {
          this.wards = await this.fecthWards(this.dataUserId.districtId)
          this.choiceWard = true
        }
      }
    },
  },
  async created() {
    if (this.userRole.unitId) {
      this.fetchUserId()
    }
    this.provinces = await this.fecthProvinces()
  },
  methods: {
    ...mapActions('business', ['fecthProvinces', 'fecthDistricts', 'fecthWards']),

    // Lấy thông tin người dùng theo id
    async fetchUserId() {
      const { data } = await axiosApiInstance.get(`/EducationDepartment/education-department/${this.userRole.unitId}`)
      this.dataUserId = data
    },

    async openSelectDistrict() {
      if (this.provinces.length > 0 && this.accountInformation.provinceId !== null) {
        const data = await this.fecthDistricts(this.accountInformation.provinceId)
        this.districts = data
        this.accountInformation.districtId = null
        this.choiceDistrict = true
      }
      if (this.accountInformation.provinceId === null) {
        this.accountInformation.districtId = null
        this.accountInformation.wardId = null
        this.choiceDistrict = false
        this.choiceWard = false
      }
    },

    async openSelectWard() {
      if (this.provinces.length > 0 && this.accountInformation.districtId !== null) {
        const data = await this.fecthWards(this.accountInformation.districtId)
        this.wards = data
        this.accountInformation.wardId = null
        this.choiceWard = true
      }
      if (this.accountInformation.districtId === null) {
        this.accountInformation.wardId = null
        this.choiceWard = false
      }
    },

    async hadnleCreate(bvModalEvt) {
      const model = {
        ...this.accountInformation,
        id: this.userRole.unitId,
      }
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.$showAllPageLoading()
          await axiosApiInstance.put('/EducationDepartment/education-department', model).then(res => {
            if (res.status === 200) {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast('Cập nhật thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              this.fetchUserId()
            }
          }).catch(e => {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(e.response.data?.errors[0], {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          })
        }
      })
    },

    // Danh sách Tỉnh/ Thành phố
    async fetchProvinces() {
      const data = await this.fecthProvinces()
      this.provinces = data
    },

    imgBT(val) {
      this.accountInformation.avatar = val
    },

    backUser() {
      this.$router.push({ name: 'dashboard-ecommerce' }).catch(() => {})
    },
  },
}
</script>

<style lang="scss">
.avatar-unit {
  .image-preview {
    .dropdown-toggle {
      .icon-camera {
        margin-top: 37px;
        margin-left: -112px;
        opacity: 0.5;
      }
    }
  }
  .dropdown-menu {
    top: 55px !important;
  }
  .image-logo-unit {
    .image-preview {
      img {
        background: #EFEFEF !important;
        object-fit: contain !important;
      }
    }
  }
}
</style>
